
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";


@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class PageBidSigning extends Vue {
    @Action("bid/requestSignUrls") requestSignUrls!: (payload: string) => Promise<any>;

    signers: any = [];

    selected: any = null;

    error = false;

    loading = true;

    async mounted() {
        try {
            this.loading = true;

            this.signers = await this.requestSignUrls(this.$route.params.id);
        } catch (e: any) {
            this.error = true;
        } finally {
            this.loading = false;
        }
    }

    openLink() {
        if (!this.selected) {
            return;
        }

        window.open(this.selected.sign_url, "_blank");
    }
}
